import React from "react";
import { useData } from "../../hooks/useData";
import { Col } from "react-bootstrap";
import { ProgressCard } from "../CardDesign";
import FullPageLoading from "../../components/loading/FullPageLoading";
import { getReportCardsProps } from "./helpers/report-cards.helpers";

const ReportCards = () => {
  const [
    customerReport = {
      customersCount: 0,
      lastMonthCustomersCount: 0,
      totalRevenue: 0,
      lastMonthRevenue: 0,
    },
    isLoading,
    hasError,
  ] = useData({
    endpoint: "purchase/report",
  });

  const reportCardsProps = getReportCardsProps(customerReport);

  return (
    <>
      {isLoading ? <FullPageLoading /> : null}
      {reportCardsProps.map((item, index) => (
        <Col xl={"6"} xxl={"6"} sm={"6"} key={index}>
          <div className="widget-stat card">
            <ProgressCard
              title={item.title}
              number={item.number}
              percent={item.percent}
              color={item.color}
              description={item.description}
            />
          </div>
        </Col>
      ))}
    </>
  );
};

export default ReportCards;
