import axiosInstance from "../AxiosInstance";

export function createBusiness(variables) {
  return axiosInstance.post(``, variables);
}

export function getBusiness() {
  return axiosInstance.get(``);
}

export function updateBusiness(variables) {
  return axiosInstance.put(``, variables);
}

export function updateBusinessPaymentInfo(variables) {
  return axiosInstance.put(`payment-info`, variables);
}

export function updateBusinessInfo(variables) {
  return axiosInstance.put(`info`, variables);
}

export function createBusinessLink(variables) {
  return axiosInstance.post(`link`, variables);
}

export function deleteBusinessLink(variables) {
  return axiosInstance.delete(`link/${variables.linkId}`);
}

export function updateBusinessLocation(variables) {
  return axiosInstance.put(`location`, variables);
}

export function getBusinessAmenities(id) {
  return axiosInstance.get(`amenities`);
}

export function updateBusinessAmenities(variables) {
  return axiosInstance.patch(`amenities`, variables);
}

export function updateBusinessLegalInfo(variables) {
  return axiosInstance.put(`legal-info`, variables);
}
