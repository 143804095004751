import { LOCAL_STORAGE_KEYS } from "../../store/constants/local-storage.constants";

export const getAccessToken = () => {
  const userDetailString = localStorage.getItem(
    LOCAL_STORAGE_KEYS.FOR_BODY_USER,
  );
  const user = userDetailString ? JSON.parse(userDetailString) : null;

  return user && user.accessToken ? user.accessToken : null;
};
