import { useData } from "../../../../hooks/useData";
import React, { useCallback, useMemo } from "react";
import Loading from "../../../../components/loading/Loading";
import Error from "../../../../components/error/Error";
import Empty from "../../../../components/empty/Empty";
import { getFormattedDate } from "../../../../utils/date";
import { AGENDA_COLUMNS } from "../constants/agenda.constants";
import TableWrapper from "../../../../components/tableWrapper/TableWrapper";

const useAgenda = () => {
  const [purchasedServices = { active: [], passive: [] }, isLoading, hasError] =
    useData({
      endpoint: "purchase/service",
    });

  const columns = AGENDA_COLUMNS;

  console.log(purchasedServices);
  const getContent = useCallback(
    (eventKey) => {
      if (isLoading) {
        return <Loading />;
      }

      if (hasError) {
        return (
          <Error description={"Üyelikleri listelerken bir hata oluştu."} />
        );
      }

      const data =
        eventKey === "PassiveServices"
          ? purchasedServices.passive
          : purchasedServices.active;

      const isEmpty = !data.length;

      if (isEmpty) {
        return <Empty description={"Servis bulunamadı."} />;
      }

      return (
        <TableWrapper>
          <table id="example4" className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {columns.map((item, index) => (
                  <th key={index}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((service, index) => (
                <tr key={index}>
                  <td>{service.title}</td>
                  <td>{service.type}</td>
                  <td>{`${service.purchase.customer.user.profile.firstName} ${service.purchase.customer.user.profile.lastName}`}</td>
                  <td>
                    {service.type === "MEMBERSHIP" ? "-" : service.serviceCount}
                  </td>
                  <td>{service.usages.length}</td>
                  <td>{getFormattedDate(service.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      );
    },
    [hasError, isLoading, purchasedServices],
  );

  return {
    getContent,
  };
};

export default useAgenda;
