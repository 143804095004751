import React, { useMemo } from "react";
import { FEE_COLUMNS } from "../constants/fees.constants";
import { getFormattedDate } from "../../../../utils/date";
import { useData } from "../../../../hooks/useData";
import Loading from "../../../../components/loading/Loading";
import Error from "../../../../components/error/Error";
import { getLanguageObject } from "../../../../utils/language";
import Empty from "../../../../components/empty/Empty";
import TableWrapper from "../../../../components/tableWrapper/TableWrapper";

const useFees = () => {
  const [fees = [], isLoading, hasError, getFees] = useData({
    endpoint: "fee",
  });

  const columns = FEE_COLUMNS;

  const Content = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return (
        <Error
          description={getLanguageObject().pages.fees.list.message.error}
        />
      );
    }

    const isEmpty = !fees.length;

    if (isEmpty) {
      return (
        <Empty
          description={getLanguageObject().pages.fees.list.message.empty}
        />
      );
    }

    return (
      <TableWrapper>
        <table id="example4" className="display dataTable no-footer w-100">
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th key={index}>{item.heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, index) => (
              <tr key={index}>
                <td>{fee.paymentId}</td>
                <td>{fee.status}</td>
                <td>{getFormattedDate(fee.createdAt)}</td>
                <td>{getFormattedDate(fee.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    );
  }, [fees]);

  return {
    Content,
  };
};

export default useFees;
