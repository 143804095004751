import React, { useMemo, useState } from "react";
import { useData } from "../../../../hooks/useData";
import { deleteEmployee } from "../../../../../services/modules/EmployeeService";
import Loading from "../../../../components/loading/Loading";
import Error from "../../../../components/error/Error";
import Empty from "../../../../components/empty/Empty";
import { Link } from "react-router-dom";
import { getLanguageObject } from "../../../../utils/language";
import { EMPLOYEE_COLUMNS } from "../constants/employees.constants";
import { getFormattedDate } from "../../../../utils/date";
import TableWrapper from "../../../../components/tableWrapper/TableWrapper";

const useEmployees = () => {
  const [employees = [], isLoading, hasError, getEmployees] = useData({
    endpoint: "employee",
  });

  const defaultSubmitEmployeeModalState = {
    visible: false,
    employeeId: "",
  };

  const [submitEmployeeModalState, setSubmitEmployeeModalState] = useState(
    defaultSubmitEmployeeModalState,
  );

  const handleOpenSubmitEmployeeModal = (employeeId = "") => {
    setSubmitEmployeeModalState({ employeeId, visible: true });
  };

  const handleCloseEmployeeModal = () => {
    setSubmitEmployeeModalState(defaultSubmitEmployeeModalState);
  };

  const refreshEmployees = () => {
    getEmployees();
  };

  const handleDeleteEmployee = (id) => {
    deleteEmployee(id).then((response) => {
      if (response.status === 200) {
        refreshEmployees();
      }
    });
  };

  const columns = EMPLOYEE_COLUMNS;

  const Content = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return (
        <Error
          description={getLanguageObject().pages.employees.list.message.error}
        />
      );
    }

    const isEmpty = !employees.length;

    if (isEmpty) {
      return (
        <Empty
          description={getLanguageObject().pages.employees.list.message.empty}
        />
      );
    }

    return (
      <TableWrapper>
        <table id="example4" className="display dataTable no-footer w-100">
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th key={index}>{item.heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => (
              <tr key={index}>
                <td>{employee.email}</td>
                <td>{employee.profile.firstName}</td>
                <td>{employee.profile.lastName}</td>
                <td>{employee.profile.phone}</td>
                <td>{getFormattedDate(employee.profile.createdAt)}</td>

                <td>
                  <Link
                    key={index}
                    onClick={() => handleOpenSubmitEmployeeModal(employee.id)}
                    className="btn btn-xs sharp btn-primary me-1"
                  >
                    <i className="fa fa-pencil" />
                  </Link>
                  <Link
                    key={index}
                    onClick={() => handleDeleteEmployee(employee.id)}
                    className="btn btn-xs sharp btn-danger"
                  >
                    <i className="fa fa-trash" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    );
  }, [hasError, isLoading, employees]);

  const submitEmployeeModalProps = {
    onClose: handleCloseEmployeeModal,
    showSubmitEmployeeModal: submitEmployeeModalState.visible,
    employeeId: submitEmployeeModalState.employeeId,
    getEmployees: getEmployees,
  };

  return {
    Content,
    handleOpenSubmitEmployeeModal,
    submitEmployeeModalProps,
  };
};

export default useEmployees;
