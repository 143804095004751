import React, { useMemo } from "react";
import { PURCHASE_COLUMNS } from "../constants/purchases.constants";
import { getFormattedDate } from "../../../../utils/date";
import { useData } from "../../../../hooks/useData";
import Loading from "../../../../components/loading/Loading";
import Error from "../../../../components/error/Error";
import { getLanguageObject } from "../../../../utils/language";
import Empty from "../../../../components/empty/Empty";
import { formatCurrency } from "../../../../utils/number";
import TableWrapper from "../../../../components/tableWrapper/TableWrapper";

const usePurchases = () => {
  const [purchases = [], isLoading, hasError] = useData({
    endpoint: "purchase",
  });

  const columns = PURCHASE_COLUMNS;

  const Content = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return (
        <Error
          description={getLanguageObject().pages.purchase.list.message.error}
        />
      );
    }

    const isEmpty = !purchases.length;

    if (isEmpty) {
      return (
        <Empty
          description={getLanguageObject().pages.purchase.list.message.empty}
        />
      );
    }

    return (
      <TableWrapper>
        <table id="example4" className="display dataTable no-footer w-100">
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th key={index}>{item.heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {purchases.map((purchase, index) => (
              <tr key={index}>
                <td>{purchase.customer.user.profile.firstName}</td>
                <td>{purchase.customer.user.profile.lastName}</td>

                {purchase.services.map((soruce) => {
                  return (
                    <>
                      <td>{soruce.title}</td>
                      <td>{soruce.status}</td>
                      <td>{soruce.type}</td>
                      <td>{formatCurrency(soruce.price)}</td>
                    </>
                  );
                })}
                <td>{getFormattedDate(purchase.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    );
  }, [purchases]);

  return {
    Content,
  };
};

export default usePurchases;
