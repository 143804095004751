import { getLanguageObject } from "../../../../../../utils/language";

export const CONTRACTS = [
  {
    id: 1,
    title: "Sözleşme 1",
    description: "Bu, birinci sözleşmedir. İçeriği hakkında kısa bir açıklama.",
    imageUrl: "https://placehold.co/600x400",
    fileUrl: "https://pdfobject.com/pdf/sample.pdf",
  },
  {
    id: 2,
    title: "Sözleşme 2",
    description: "İkinci sözleşme. İçeriği farklıdır.",
    imageUrl: "https://placehold.co/600x400",
    fileUrl: "https://pdfobject.com/pdf/sample.pdf",
  },
  {
    id: 3,
    title: "Sözleşme 3",
    description: "Üçüncü sözleşme örneği.",
    imageUrl: "https://placehold.co/600x400",
    fileUrl: "https://pdfobject.com/pdf/sample.pdf",
  },
];

export const LEGAL_INFO_FORM_INPUTS = [
  {
    field: getLanguageObject().pages.business_profile.legal_info.taxNo,
    name: "taxNo",
    defaultValue: "",
    placeholder: getLanguageObject().pages.business_profile.legal_info.taxNo,
    type: "text",
  },
  {
    field: getLanguageObject().pages.business_profile.legal_info.kep_address,
    name: "kepAddress",
    defaultValue: "",
    placeholder:
      getLanguageObject().pages.business_profile.legal_info.kep_address,
    type: "text",
  },
  {
    field:
      getLanguageObject().pages.business_profile.legal_info.identity_number,
    name: "identityNumber",
    defaultValue: "",
    placeholder:
      getLanguageObject().pages.business_profile.legal_info.identity_number,
    type: "text",
    minLength: 11,
    maxLength: 11,
  },

  {
    field: getLanguageObject().pages.business_profile.legal_info.mersis_no,
    name: "mersisNo",
    defaultValue: "",
    placeholder:
      getLanguageObject().pages.business_profile.legal_info.mersis_no,
    type: "text",
    minLength: 16,
    maxLength: 16,
  },
  {
    field: getLanguageObject().pages.business_profile.legal_info.center_address,
    name: "centerAddress",
    defaultValue: "",
    placeholder:
      getLanguageObject().pages.business_profile.legal_info.center_address,
    type: "text",
  },
  {
    field:
      getLanguageObject().pages.business_profile.legal_info
        .approved_electronic_contact_address,
    name: "approvedElectronicContactAddress",
    defaultValue: "",
    placeholder:
      getLanguageObject().pages.business_profile.legal_info
        .approved_electronic_contact_address,
    type: "text",
  },
];
