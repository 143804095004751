import { useEffect, useState } from "react";
import {
  handleUpdateBusiness,
  handleUpdateBusinessInfo,
} from "../../../actions/business";
import useDeviceType from "../../../../../../hooks/useDeviceType";
import {
  DEFAULT_CROP_SIZE,
  MOBILE_CROP_SIZE,
} from "../../../../../../components/imageUploader/constants/image-uplaoder.constants";

const useBusinessCoverImage = ({ business, onBusinessUpdated }) => {
  const [selectedCoverImage, setSelectedCoverImage] = useState("");

  useEffect(() => {
    if (business?.coverImage) {
      setSelectedCoverImage(business.coverImage);
    }
  }, [business?.coverImage]);

  const handleUpdateBusinessCoverImage = () => {
    const variables = {
      coverImage: selectedCoverImage,
    };

    handleUpdateBusiness({
      variables,
      onSuccess: () => {
        setSelectedCoverImage("");
        onBusinessUpdated();
      },
      onFailure: () => {},
    });
  };

  const handleChangeCoverImage = (files = []) => {
    const coverImageUrl = files[0]?.location;

    setSelectedCoverImage(coverImageUrl);
  };

  const isMobile = useDeviceType();

  const shouldShowAdjustedCropSize = isMobile
    ? MOBILE_CROP_SIZE
    : DEFAULT_CROP_SIZE;

  return {
    selectedCoverImage,
    handleUpdateBusinessCoverImage,
    handleChangeCoverImage,
    shouldShowAdjustedCropSize,
  };
};
export default useBusinessCoverImage;
