import { getLanguageObject } from "../../../utils/language";
import { REPORT_CARDS_PROPS } from "../constants/report-cards.constants";
import {
  calculatePercentageChange,
  formatCurrency,
} from "../../../utils/number";

export const getReportCardsProps = (customerReport) => [
  {
    ...REPORT_CARDS_PROPS.customersCount,
    number: customerReport.customersCount,
    percent: "100%",
  },
  {
    ...REPORT_CARDS_PROPS.lastMonthCustomersCount,
    description: `${REPORT_CARDS_PROPS.lastMonthRevenue.description} (%${calculatePercentageChange(
      customerReport.customersCount - customerReport.lastMonthCustomersCount,
      customerReport.lastMonthCustomersCount,
    )} ${getLanguageObject().pages.common.increase})`,
    number: customerReport.lastMonthCustomersCount,
    percent: "100%",
  },
  {
    ...REPORT_CARDS_PROPS.totalRevenue,
    number: formatCurrency(customerReport.totalRevenue),
    percent: "100%",
  },
  {
    ...REPORT_CARDS_PROPS.lastMonthRevenue,
    description: `${REPORT_CARDS_PROPS.lastMonthRevenue.description} (%${calculatePercentageChange(
      customerReport.totalRevenue - customerReport.lastMonthRevenue,
      customerReport.lastMonthRevenue,
    )} ${getLanguageObject().pages.common.increase})`,
    number: formatCurrency(customerReport.lastMonthRevenue),
    percent: "100%",
  },
];
