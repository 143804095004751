import { toast } from "react-toastify";
import {
  createBusinessLink,
  deleteBusinessLink,
  updateBusiness,
  updateBusinessAmenities,
  updateBusinessInfo,
  updateBusinessLegalInfo,
  updateBusinessLocation,
  updateBusinessPaymentInfo,
} from "../../../../../services/modules/BusinessService";

const onSuccess = () => {};

const onFailure = (text) => {
  toast.error(`❌ ${text} !`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const handleUpdateBusiness = async ({ variables, onSuccess, onFailure }) => {
  try {
    await updateBusiness(variables);
    onSuccess?.();
  } catch (error) {
    onFailure?.(error);
  }
};

export const handleUpdateBusinessAmenities = async ({
  variables,
  onSuccess,
  onFailure,
}) => {
  try {
    await updateBusinessAmenities(variables);
    onSuccess?.();
  } catch (error) {
    onFailure?.(error);
  }
};

const handleUpdateBusinessInfo = async ({
  variables,
  onSuccess,
  onFailure,
}) => {
  try {
    await updateBusinessInfo(variables);
    onSuccess?.();
  } catch (error) {
    onFailure(error)?.(error);
  }
};

const createBusinessInfoLink = async (variables) => {
  try {
    await createBusinessLink(variables);
    onSuccess?.();
  } catch (error) {
    onFailure?.(error);
  }
};

const deleteBusinessInfoLink = async (variables) => {
  try {
    await deleteBusinessLink(variables);
    onSuccess?.();
  } catch (error) {
    onFailure?.(error);
  }
};

const handleUpdateBusinessPaymentInfo = async ({
  variables,
  onSuccess,
  onFailure,
}) => {
  try {
    await updateBusinessPaymentInfo(variables);
    onSuccess?.();
  } catch (error) {
    onFailure()?.(error);
  }
};

const handleUpdateBusinessLocation = async ({ variables, onSuccess }) => {
  try {
    await updateBusinessLocation(variables);
    onSuccess?.();
  } catch (error) {
    onFailure()?.(error);
  }
};

const handleUpdateBusinessLegalInfo = async ({
  variables,
  onSuccess,
  onFailure,
}) => {
  try {
    await updateBusinessLegalInfo(variables);
    onSuccess?.();
  } catch (error) {
    onFailure()?.(error);
  }
};

export {
  handleUpdateBusinessPaymentInfo,
  handleUpdateBusinessInfo,
  handleUpdateBusiness,
  createBusinessInfoLink,
  deleteBusinessInfoLink,
  handleUpdateBusinessLocation,
  handleUpdateBusinessLegalInfo,
};
