import React from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import { getLanguageObject } from "../../../../../utils/language";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { UploadButton } from "./BusinessImages.styled";
import useBusinessImages from "./hooks/useBusinessImages";
import ImageUploader from "../../../../../components/imageUploader/ImageUploader";

const BusinessImages = ({ business, onBusinessUpdated, onInit }) => {
  const {
    businessImages,
    handleSubmit,
    handleChangeImages,
    handleRemoveImage,
    selectedImages,
    shouldShowAdjustedCropSize,
  } = useBusinessImages({
    business,
    onBusinessUpdated,
  });

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h5 className="text-primary ">
            {getLanguageObject().pages.business_profile.business_images.title}
          </h5>

          <div className="d-flex align-items-center justify-content-center gap-4">
            <ImageUploader
              business={business}
              multiple={true}
              onFilesUploaded={handleChangeImages}
              cropSize={shouldShowAdjustedCropSize}
            >
              <button style={UploadButton}>
                <FileUploadIcon className="fs-3" />
              </button>
            </ImageUploader>

            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={!selectedImages.length}
            >
              {selectedImages.length ? (
                <>{selectedImages.length} Resim </>
              ) : null}

              {
                getLanguageObject().pages.business_profile.business_images
                  .upload_button
              }
            </button>
          </div>
        </div>
        <div className="card-body pt-3">
          <div className="profile-interest">
            {/* TODO(Emirhan): lgZoom plugini kullanımda iken görsele tıklayınca ya da silince hata veriyor. Sunucu da ki halinde de varmış çözüm bulamadım şimdilik kaldırdım. */}
            <LightGallery
              onInit={onInit}
              speed={500}
              plugins={[lgThumbnail]}
              elementClassNames="row sp4"
            >
              {businessImages?.map((item, index) => (
                <div
                  data-src={item}
                  className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1"
                  key={index}
                  onClick={(event) => event.stopPropagation()}
                >
                  <button
                    className="btn btn-danger btn-xxs position-absolute"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleRemoveImage(item);
                    }}
                  >
                    -
                  </button>
                  <img
                    src={item}
                    style={{ width: "100%" }}
                    alt={`gallery${index}`}
                  />
                </div>
              ))}
            </LightGallery>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessImages;
