import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../services/AxiosInstance";

export const useData = ({
  endpoint,
  method = "get",
  fetchOnce = true,
  id,
  nameSpace = "business",
}) => {
  const [state, setState] = useState({
    data: undefined,
    isLoading: true,
    hasError: false,
    count: 0,
  });

  const fetchingStart = () => {
    setState({ ...state, isLoading: true });
  };

  const fetchingSuccess = (data) => {
    setState({ ...state, data, hasError: false, isLoading: false });
  };

  const fetchingError = () => {
    setState({ ...state, data: undefined, hasError: true, isLoading: false });
  };

  const getData = useCallback(() => {
    if (endpoint) {
      fetchingStart();
      let instance = axiosInstance;

      instance[method](endpoint)
        .then((response) => {
          const isRequestSuccessful =
            response?.data?.id ||
            response?.data?.active ||
            response.data.status === 200 ||
            Array.isArray(response.data) ||
            Array.isArray(response.data?.data);

          if (isRequestSuccessful) {
            return fetchingSuccess(response.data);
          }

          return fetchingError();
        })
        .catch((error) => {
          fetchingError();
        });
    }
  }, [endpoint, method]);

  useEffect(() => {
    if (fetchOnce) {
      getData();
    }
  }, [fetchOnce, getData]);

  return [state.data, state.isLoading, state.hasError, getData];
};
