import axios from "axios";
import { getAuthorizationHeader } from "../jsx/utils/request";
import { LOCAL_STORAGE_KEYS } from "../store/constants/local-storage.constants";
import { toast } from "react-toastify";

const isDev = false;

export const BASE_URL = isDev
  ? "http://localhost:3001/business"
  : "https://api.forbody.app/business";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, ...getAuthorizationHeader() };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const handleLogout = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FOR_BODY_USER);
  toast.error("Kullanıcı bulunamadı. Anasayfaya yönlendiriliyorsunuz");
  window.location.href = "/login";
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      return handleLogout();
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
