import React from "react";
import { getLanguageObject } from "../../../utils/language";

const HelpCenter = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className=" card card-body px-3 pt-3 pb-0">
          <div className="profile-info">
            <div className="profile-details">
              <div className="profile-name px-3 pt-2">
                <h4 className="text-primary mb-0">
                  {getLanguageObject().pages.help_center.get_in_touch}
                </h4>
              </div>
              <div className="profile-email px-2 pt-2">
                <h4 className="text-muted mb-0">
                  <a href="mailto:destek@forbody.app">destek@forbody.app</a>
                </h4>
                <p>Email</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
