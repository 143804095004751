import React from "react";
import { getLanguageObject } from "../../../../../utils/language";
import {
  CONTRACTS,
  LEGAL_INFO_FORM_INPUTS,
} from "./constants/legal-info.constants";
import ContractInfo from "./components/ContactInfo/ContractInfo";
import useLegalInfo from "./hooks/useLegalInfo";

const LegalInfo = ({ onBusinessUpdated }) => {
  const {
    values,
    errors,
    handleChangeInput,
    handleSubmit,
    shouldSubmitLegalInfo,
    toggleLegalInfoSubmit,
  } = useLegalInfo({ onBusinessUpdated });

  return (
    <div className="p-3">
      <div className="settings-form">
        <h4 className="text-primary">
          {getLanguageObject().pages.business_profile.legal_info.title}
        </h4>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row g-3">
            {LEGAL_INFO_FORM_INPUTS.map((formInput) => (
              <div className="col-md-6" key={formInput.field}>
                <div className="form-group">
                  <label className="mb-1">
                    <strong>
                      {formInput.field}
                      {formInput.required && (
                        <span className="text-danger">*</span>
                      )}
                    </strong>
                  </label>
                  <input
                    type={formInput.type}
                    className="form-control"
                    value={values[formInput.field] || ""}
                    onChange={(event) =>
                      handleChangeInput(formInput.field, event.target.value)
                    }
                    placeholder={formInput.placeholder}
                    required={formInput.required}
                    disabled={!shouldSubmitLegalInfo}
                    maxLength={formInput.maxLength}
                    minLength={formInput.minLength}
                  />
                  {errors[formInput.field] && (
                    <div className="text-danger fs-12">
                      {errors[formInput.field]}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {shouldSubmitLegalInfo ? (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleSubmit}
            >
              {getLanguageObject().pages.common.form.submit_button}
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => toggleLegalInfoSubmit(true)}
            >
              {getLanguageObject().pages.common.form.edit_button}
            </button>
          )}
        </form>
      </div>
      <ContractInfo contracts={CONTRACTS} />
    </div>
  );
};

export default LegalInfo;
