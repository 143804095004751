import React from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import LightGallery from "lightgallery/react";
import Select from "react-select";
import { getLanguageObject } from "../../../../../utils/language";
import useBusinessInfo from "./hooks/useBusinessInfo";
import ImageUploader from "../../../../../components/imageUploader/ImageUploader";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import { Button } from "rsuite";
import { LOGO_CROP_SIZE } from "../../../../../components/imageUploader/constants/image-uplaoder.constants";

const BusinessInfo = ({ business, onBusinessUpdated, onInit }) => {
  const {
    values,
    types,
    handleChangeInput,
    handleSubmitInfo,
    shouldSubmitInfo,
    toggleInfoSubmit,
    amenities,
    shouldSubmitAmenities,
    toggleAmenitiesSubmit,
    handleSubmitAmenities,
    businessLogoList,
  } = useBusinessInfo({
    business,
    onBusinessUpdated,
  });

  return (
    <>
      <div className="p-3">
        <div className="settings-form">
          <h4 className="text-primary">
            {getLanguageObject().pages.business_profile.about.title}
          </h4>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.name}
                  <strong>
                    <span className={"required"}>*</span>
                  </strong>
                </label>

                <input
                  required
                  type="text"
                  disabled={!shouldSubmitInfo}
                  placeholder="Name"
                  className="form-control"
                  onChange={(e) => handleChangeInput("name", e.target.value)}
                  value={values.name}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.description}
                  <strong>
                    <span className={"required"}>*</span>
                  </strong>
                </label>
                <input
                  required
                  type="text"
                  disabled={!shouldSubmitInfo}
                  placeholder="Description"
                  className="form-control"
                  onChange={(e) =>
                    handleChangeInput("description", e.target.value)
                  }
                  value={values.description}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.type}
                  <strong>
                    <span className={"required"}>*</span>
                  </strong>
                </label>
                <Select
                  placeholder={
                    getLanguageObject().pages.business_profile.about.select_type
                  }
                  isDisabled={!shouldSubmitInfo}
                  isMulti
                  options={types}
                  isSearchable={false}
                  onChange={(e) => handleChangeInput("types", e)}
                  value={values.types}
                  className="custom-react-select"
                  required
                />
              </div>

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor="logo" className="font-w600">
                    {getLanguageObject().pages.employees.form.avatar}
                  </label>

                  <div className="d-flex gap-3">
                    <ImageUploader
                      fileList={businessLogoList}
                      cropSize={LOGO_CROP_SIZE}
                      onFilesUploaded={(images) => {
                        handleChangeInput("logo", images[0].location);
                      }}
                    >
                      <Button>
                        <AvatarIcon />
                      </Button>
                    </ImageUploader>

                    <div className="profile-interest">
                      <LightGallery
                        onInit={onInit}
                        speed={500}
                        plugins={[lgThumbnail]}
                        elementClassNames="row sp4"
                      >
                        {businessLogoList.length ? (
                          <div
                            data-src={businessLogoList[0]?.url}
                            className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mt-2"
                            onClick={(event) => event.stopPropagation()}
                          >
                            <button
                              className="btn btn-danger btn-xxs position-absolute"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleChangeInput("logo", null);
                              }}
                            >
                              -
                            </button>
                            <img
                              src={businessLogoList[0]?.url}
                              style={{ width: 65, height: 65 }}
                              alt="logo"
                            />
                          </div>
                        ) : null}
                      </LightGallery>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {shouldSubmitInfo ? (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handleSubmitInfo}
              >
                {getLanguageObject().pages.common.form.submit_button}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => toggleInfoSubmit(true)}
              >
                {getLanguageObject().pages.common.form.edit_button}
              </button>
            )}
          </form>
        </div>
      </div>

      <hr />

      <div className="p-3 mt-3">
        <div className="settings-form">
          <h4 className="text-primary">
            {getLanguageObject().pages.business_profile.amenities.title}
          </h4>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group mb-3 col-md-8">
                <label className="form-label">
                  {
                    getLanguageObject().pages.business_profile.amenities
                      .business_amenities
                  }
                </label>
                <Select
                  placeholder={
                    getLanguageObject().pages.business_profile.amenities
                      .select_amenities
                  }
                  isDisabled={!shouldSubmitAmenities}
                  isMulti
                  options={amenities}
                  isSearchable={false}
                  onChange={(e) => handleChangeInput("amenities", e)}
                  value={values.amenities}
                  className="custom-react-select"
                />
              </div>
            </div>

            {shouldSubmitAmenities ? (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handleSubmitAmenities}
              >
                {getLanguageObject().pages.common.form.submit_button}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => toggleAmenitiesSubmit(true)}
              >
                {getLanguageObject().pages.common.form.edit_button}
              </button>
            )}
          </form>
        </div>
      </div>

      <hr />
      <div className="profile-personal-info p-3 mt-3">
        <h4 className="text-primary mb-4">
          {getLanguageObject().pages.business_profile.info.title}
        </h4>

        <div className="row mb-2">
          <div className="col-3">
            <h5 className="f-w-500">
              {getLanguageObject().pages.business_profile.info.address}
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-9">
            <span>{business?.info?.address || "-"}</span>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-3">
            <h5 className="f-w-500">
              {getLanguageObject().pages.business_profile.info.employees}
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-9">
            <p>{business?.employees?.length}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInfo;
