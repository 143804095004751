import { getLanguageObject } from "../../../utils/language";

export const REPORT_CARDS_PROPS = {
  customersCount: {
    title: getLanguageObject().pages.home.report_cards.customer.title,
    color: "primary",
    description:
      getLanguageObject().pages.home.report_cards.customer.description,
  },
  lastMonthCustomersCount: {
    title:
      getLanguageObject().pages.home.report_cards.last_month_customer.title,
    color: "danger",
    description:
      getLanguageObject().pages.home.report_cards.last_month_customer
        .description,
  },
  totalRevenue: {
    title: getLanguageObject().pages.home.report_cards.total_revenue.title,
    color: "info",
    description:
      getLanguageObject().pages.home.report_cards.total_revenue.description,
  },
  lastMonthRevenue: {
    title: getLanguageObject().pages.home.report_cards.last_month_revenue.title,
    color: "success",
    description:
      getLanguageObject().pages.home.report_cards.last_month_revenue
        .description,
  },
};
