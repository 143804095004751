import { getLanguageObject } from "../../../../../../utils/language";

export const validateIdentityNumber = (identityNumber) => {
  if (!identityNumber) return true;
  if (identityNumber?.length !== 11) return false;
  if (isNaN(identityNumber)) return false;
  if (Number(identityNumber[0]) === 0) return false;

  return true;
};

export const validateMersisNo = (mersisNo) => {
  if (!mersisNo) return true;
  if (mersisNo?.length !== 16) return false;
  if (isNaN(mersisNo)) return false;

  return true;
};

export const validateKepAddress = (kepAddress) => {
  if (!kepAddress) return true;

  return String(kepAddress)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validateLegalInfoForm = (values, setErrors) => {
  let hasError = false;
  const errors = {};

  if (!validateIdentityNumber(values.identityNumber)) {
    errors.identityNumber =
      getLanguageObject().pages.business_profile.legal_info.form_errors.identity_number;

    hasError = true;
  }

  if (!validateMersisNo(values.mersisNo)) {
    errors.mersisNo =
      getLanguageObject().pages.business_profile.legal_info.form_errors.mersis_no;

    hasError = true;
  }

  if (!validateKepAddress(values.kepAddress)) {
    errors.kepAddress =
      getLanguageObject().pages.business_profile.legal_info.form_errors.kep_address;

    hasError = true;
  }

  setErrors(errors);

  return hasError;
};
