import React from "react";
import { Button, Card } from "react-bootstrap";

const ContractInfo = ({ contracts }) => {
  const handleClickContract = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="d-flex flex-wrap pt-5 gap-3">
      {contracts.map((contract, index) => (
        <div key={index} style={{ width: "9rem" }} className="text-center">
          <Card.Title>{contract.title}</Card.Title>
          <Card.Img
            variant="top"
            src={contract.imageUrl}
            alt={contract.title}
            style={{ cursor: "pointer" }}
            onClick={() => handleClickContract(contract.fileUrl)}
          />
          <Button
            className="mt-2"
            onClick={() => handleClickContract(contract.fileUrl)}
          >
            İndir
          </Button>
        </div>
      ))}
    </div>
  );
};

export default ContractInfo;
