import { getLanguageObject } from "../../../utils/language";

export const getLevelSelectionTranslation = (level) => {
  const LevelSelectionTranslation = {
    beginner: getLanguageObject().pages.service.level.beginner,
    intermediate: getLanguageObject().pages.service.level.intermediate,
    advanced: getLanguageObject().pages.service.level.advanced,
  };

  return LevelSelectionTranslation[level.toLowerCase()] || level;
};
