import React, { Fragment } from "react";
import { Tab, Nav } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import useBusinessProfile from "./hooks/useBusinessProfile";
import Loading from "../../../components/loading/Loading";
import Error from "../../../components/error/Error";
import Empty from "../../../components/empty/Empty";
import BusinessImages from "./components/BusinessImages/BusinessImages";
import { getLanguageObject } from "../../../utils/language";
import PaymentInfo from "./components/PaymentInfo/PaymentInfo";
import BusinessInfo from "./components/BusinessInfo/BusinessInfo";
import BusinessLocation from "./components/BusinessLocation/BusinessLocation";
import BusinessWorkingHours from "./components/BusinessWorkingHours/BusinessWorkingHours";
import BusinessLinks from "./components/BusinessLinks/BusinessLinks";
import FullPageLoading from "../../../components/loading/FullPageLoading";
import BusinessCoverImage from "./components/BusinessCoverImage/BusinessCoverImage";
import LegalInfo from "./components/LegalInfo/LegalInfo";

const BusinessProfile = () => {
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  const {
    business,
    businessAdminUser,
    isLoading,
    hasError,
    isEmpty,
    handleGetBusiness,
  } = useBusinessProfile();

  if (hasError) {
    return <Error description={"İşletme listelerken bir hata oluştu."} />;
  }

  return (
    <Fragment>
      <PageTitle activeMenu="Business Profile" motherMenu="App" />

      {isLoading ? (
        <FullPageLoading />
      ) : isEmpty ? (
        <Empty description={"İşletme bulunamadı."} />
      ) : null}
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="profile-info">
                <div className="profile-photo">
                  {business?.logo ? (
                    <img
                      src={business?.logo}
                      className="img-fluid rounded-2"
                      alt="logo"
                    />
                  ) : null}
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{business?.name}</h4>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">
                      {businessAdminUser?.email}
                    </h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4">
          <div className="row">
            <BusinessCoverImage
              business={business}
              onBusinessUpdated={handleGetBusiness}
              onInit={onInit}
            />

            <BusinessImages
              business={business}
              onBusinessUpdated={handleGetBusiness}
              onInit={onInit}
            />

            <BusinessWorkingHours
              business={business}
              onBusinessUpdated={handleGetBusiness}
            />
          </div>
        </div>
        <div className="col-xl-8">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey="About">
                    <Nav as="ul" className="nav nav-tabs">
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link to="#about" eventKey="About">
                          {
                            getLanguageObject().pages.business_profile.list
                              .about
                          }
                          <strong>
                            <span className={"required"}>*</span>
                          </strong>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link to="#payment-info" eventKey="PaymentInfo">
                          {
                            getLanguageObject().pages.business_profile
                              .payment_info.title
                          }
                          <strong>
                            <span className={"required"}>*</span>
                          </strong>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link to="#legal-info" eventKey="LegalInfo">
                          {
                            getLanguageObject().pages.business_profile
                              .legal_info.title
                          }
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link
                          to="#business-location"
                          eventKey="BusinessLocation"
                        >
                          {
                            getLanguageObject().pages.business_profile.location
                              .title
                          }
                          <strong>
                            <span className={"required"}>*</span>
                          </strong>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link to="#business-links" eventKey="BusinessLinks">
                          {
                            getLanguageObject().pages.business_profile.links
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane id="about" eventKey="About">
                        <BusinessInfo
                          business={business}
                          onBusinessUpdated={handleGetBusiness}
                          onInit={onInit}
                        />
                      </Tab.Pane>

                      <Tab.Pane id="payment-info" eventKey="PaymentInfo">
                        <PaymentInfo
                          business={business}
                          onBusinessUpdated={handleGetBusiness}
                        />
                      </Tab.Pane>

                      <Tab.Pane id="legal-info" eventKey="LegalInfo">
                        <LegalInfo onBusinessUpdated={handleGetBusiness} />
                      </Tab.Pane>

                      <Tab.Pane
                        id="business-location"
                        eventKey="BusinessLocation"
                      >
                        <BusinessLocation
                          business={business}
                          onBusinessUpdated={handleGetBusiness}
                        />
                      </Tab.Pane>

                      <Tab.Pane id="business-links" eventKey="BusinessLinks">
                        <BusinessLinks
                          business={business}
                          onBusinessUpdated={handleGetBusiness}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BusinessProfile;
