import React, { useEffect, useMemo, useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Button, Modal } from "react-bootstrap";
import { getLanguageObject } from "../../../../utils/language";

const ImageCropper = ({
  files = [],
  handleCropCancel,
  handleUploadFiles,
  cropSize,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cropState, setCropState] = useState([]);

  const handleNext = async () => {
    if (currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      const croppedImages = await getCroppedImages();

      handleUploadFiles(croppedImages);
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getImages = useMemo(() => {
    return files.map((file) => URL.createObjectURL(file.blobFile));
  }, [files]);

  useEffect(() => {
    if (files.length) {
      const crops = files.map(() => ({
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedAreaPixels: null,
        rotation: 0,
      }));

      setCropState(crops);
    }
  }, [files.length]);

  const handleCropChange = (index, crop) => {
    const updatedState = [...cropState];
    updatedState[index].crop = crop;
    setCropState(updatedState);
  };

  const handleRotationChange = (index, rotation) => {
    const updatedState = [...cropState];
    updatedState[index].rotation = rotation;
    setCropState(updatedState);
  };

  const handleZoomChange = (index, zoom) => {
    const updatedState = [...cropState];
    updatedState[index].zoom = zoom;
    setCropState(updatedState);
  };

  const handleCropComplete = (index, croppedArea, croppedAreaPixels) => {
    const updatedState = [...cropState];
    updatedState[index].croppedAreaPixels = croppedAreaPixels;

    setCropState(updatedState);
  };

  const getCroppedImages = async () => {
    const croppedImagesPromises = files.map((file, index) => {
      return getCroppedImage(
        URL.createObjectURL(file.blobFile),
        cropState[index].croppedAreaPixels,
        cropState[index].rotation,
      ).then((croppedBlob) => {
        const croppedFile = new File([croppedBlob], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        return croppedFile;
      });
    });

    const croppedImages = await Promise.all(croppedImagesPromises);
    return croppedImages;
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImage = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas to blob failed"));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  const uploaderButton =
    currentIndex < files.length - 1
      ? getLanguageObject().pages.common.image_uploader.next_image_button
      : getLanguageObject().pages.common.image_uploader.finish_button;

  return (
    <Modal show={!!files.length} onHide={handleCropCancel} centered size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {getLanguageObject().pages.common.image_uploader.image_resize}
          </h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={handleCropCancel}
          >
            <span className="font-w600">×</span>
          </Button>
        </div>
        <div
          className="modal-body d-flex bg-white justify-content-center align-items-center flex-column"
          style={{ height: "50vh" }}
        >
          {cropState.length ? (
            <Cropper
              zoomWithScroll={true}
              image={getImages[currentIndex]}
              crop={cropState[currentIndex].crop}
              zoom={cropState[currentIndex].zoom}
              rotation={cropState[currentIndex].rotation}
              aspect={4 / 3}
              showGrid={false}
              objectFit="cover"
              cropSize={cropSize}
              minZoom={0.1}
              zoomSpeed={0.1}
              onRotationChange={(rotation) =>
                handleRotationChange(currentIndex, rotation)
              }
              onCropChange={(crop) => handleCropChange(currentIndex, crop)}
              onZoomChange={(zoom) => handleZoomChange(currentIndex, zoom)}
              onCropComplete={(croppedArea, croppedAreaPixels) =>
                handleCropComplete(currentIndex, croppedArea, croppedAreaPixels)
              }
            />
          ) : null}
        </div>
        <div className="modal-footer justify-content-between">
          <input
            type="button"
            value={getLanguageObject().pages.common.pagination.previous_button}
            onClick={handleBack}
            disabled={currentIndex === 0}
            className="btn btn-primary"
          />

          <div className="controls d-flex gap-3">
            <div className="slider-container d-flex flex-column align-items-center gap-1">
              <label>
                {getLanguageObject().pages.common.image_uploader.rotation}
              </label>
              <input
                type="range"
                value={cropState[currentIndex]?.rotation || 0}
                min={0}
                max={360}
                step={1}
                onChange={(e) =>
                  handleRotationChange(currentIndex, Number(e.target.value))
                }
                className="rotation-range"
              />
            </div>
            <div className="slider-container d-flex flex-column align-items-center gap-1">
              <label>
                {getLanguageObject().pages.common.image_uploader.zoom}
              </label>
              <input
                type="range"
                value={cropState[currentIndex]?.zoom || 1}
                min={1}
                max={3}
                step={0.1}
                onChange={(e) =>
                  handleZoomChange(currentIndex, Number(e.target.value))
                }
                className="zoom-range"
              />
            </div>
          </div>

          <input
            type="button"
            value={uploaderButton}
            onClick={handleNext}
            className="btn btn-primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImageCropper;
