import { toast } from "react-toastify";
import { getLanguageObject } from "../../../../../../../utils/language";

export const validateMembershipForm = (values) => {
  const requiredFields = [
    "name",
    "description",
    "duration",
    "price",
    "branchId",
    "level",
    "platform",
  ];

  for (let field of requiredFields) {
    if (!values[field]) {
      toast.error(
        `${field} ${getLanguageObject().pages.memberships.form.error_message}`,
      );
      return false;
    }
  }

  return true;
};
