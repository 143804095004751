import { toast } from "react-toastify";
import { getLanguageObject } from "../../../../../../../../utils/language";
import { APP_TYPE } from "../../../../../../../../constant/global";

export const validatePackageLessonsForm = (values, appType) => {
  const baseFields = [
    "branchId",
    "name",
    "preparation",
    "description",
    "duration",
    "price",
    "serviceCount",
  ];

  const additionalFields = ["participantCount", "level", "platform"];

  const requiredFields =
    appType === APP_TYPE.BEAUTY
      ? baseFields
      : [...baseFields, ...additionalFields];

  for (let field of requiredFields) {
    if (!values[field]) {
      toast.error(
        `${field} ${getLanguageObject().pages.package_lessons.form.form_error_message}`,
      );
      return false;
    }
  }

  return true;
};
