import { useEffect, useState } from "react";
import { handleUpdateBusinessLegalInfo } from "../../../actions/business";
import { useData } from "../../../../../../hooks/useData";
import { validateLegalInfoForm } from "../helpers/legal-info.helpers";

const useLegalInfo = ({ onBusinessUpdated }) => {
  const [legalInfo] = useData({
    endpoint: "legal-info",
  });

  const [values, setValues] = useState({
    taxNo: null,
    kepAddress: null,
    identityNumber: null,
    mersisNo: null,
    centerAddress: null,
    approvedElectronicContactAddress: null,
  });

  const [errors, setErrors] = useState({});
  const [shouldSubmitLegalInfo, toggleLegalInfoSubmit] = useState(false);

  useEffect(() => {
    if (legalInfo) {
      setValues({
        taxNo: legalInfo.taxNo,
        kepAddress: legalInfo.kepAddress,
        identityNumber: legalInfo.identityNumber,
        mersisNo: legalInfo.mersisNo,
        centerAddress: legalInfo.centerAddress,
        approvedElectronicContactAddress:
          legalInfo.approvedElectronicContactAddress,
      });
    }
  }, [legalInfo]);

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateLegalInfoForm(values, setErrors)) {
      return null;
    }

    toggleLegalInfoSubmit(false);

    const variables = {
      taxNo: values.taxNo,
      kepAddress: values.kepAddress,
      identityNumber: values.identityNumber,
      mersisNo: values.mersisNo,
      centerAddress: values.centerAddress,
      approvedElectronicContactAddress: values.approvedElectronicContactAddress,
    };

    handleUpdateBusinessLegalInfo({
      variables,
      onSuccess: onBusinessUpdated,
    });
  };

  return {
    values,
    errors,
    handleChangeInput,
    handleSubmit,
    shouldSubmitLegalInfo,
    toggleLegalInfoSubmit,
  };
};

export default useLegalInfo;
