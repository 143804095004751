import React from "react";
import { Uploader } from "rsuite";
import { UploaderStyle } from "./ImageUploader.styles";
import useImageUploader from "./hooks/useImageUploader";
import ImageCropper from "./components/imageCropper/ImageCropper";
import { DEFAULT_CROP_SIZE } from "./constants/image-uplaoder.constants";

const ImageUploader = ({
  isDisabled,
  fileList = [],
  children,
  business,
  onFilesUploaded,
  multiple = true,
  cropSize = DEFAULT_CROP_SIZE,
}) => {
  const {
    handleChangeFiles,
    handleUploadFiles,
    handleCropCancel,
    files,
    blobFiles,
  } = useImageUploader({
    business,
    onFilesUploaded,
    fileList,
  });

  return (
    <>
      <Uploader
        style={UploaderStyle}
        disabled={isDisabled}
        multiple={multiple}
        autoUpload={false}
        listType="picture"
        fileList={files}
        fileListVisible={!!fileList.length}
        onChange={handleChangeFiles}
      >
        {children}
      </Uploader>
      {blobFiles.length ? (
        <ImageCropper
          files={blobFiles}
          handleCropCancel={handleCropCancel}
          handleUploadFiles={handleUploadFiles}
          cropSize={cropSize}
        />
      ) : null}
    </>
  );
};

export default ImageUploader;
