import { getLanguageObject } from "../../../utils/language";

export const LAST_USAGES_COLUMNS = [
  {
    heading:
      getLanguageObject().pages.home.report_cards.last_usages.columns.service,
  },
  {
    heading:
      getLanguageObject().pages.home.report_cards.last_usages.columns.customer,
  },
  {
    heading:
      getLanguageObject().pages.home.report_cards.last_usages.columns
        .usage_date,
  },
];
