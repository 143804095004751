import React from "react";
import { tableWrapperStyle } from "./TableWrapper.styles";

const TableWrapper = ({ children }) => {
  return (
    <div style={tableWrapperStyle} className="w-100">
      {children}
    </div>
  );
};

export default TableWrapper;
