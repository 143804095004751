import { getLanguageObject } from "../../../../../../utils/language";

export const getBusinessTypeTranslation = (type) => {
  const BusinessTypeTranslation = {
    fitness:
      getLanguageObject().pages.business_profile.about.business_types.fitness,
    sport:
      getLanguageObject().pages.business_profile.about.business_types.sport,
    wellness:
      getLanguageObject().pages.business_profile.about.business_types.wellness,
    spa: getLanguageObject().pages.business_profile.about.business_types.spa,
  };

  return BusinessTypeTranslation[type.toLowerCase()] || type;
};
