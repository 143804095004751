import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createMembership,
  getMembership,
  updateMembership,
} from "../../../../../../../../services/modules/MembershipService";
import { useData } from "../../../../../../../hooks/useData";
import { LEVELS, PLATFORM } from "../../../../../../../constant/global";
import { getLanguageObject } from "../../../../../../../utils/language";
import { validateMembershipForm } from "../helpers/membership-form.helpers";

const useMembershipForm = ({
  membershipId,
  showSubmitMembershipModal,
  onClose,
  getMemberships,
}) => {
  const [membership] = useData({
    fetchOnce: false,
    endpoint: `membership/${membershipId}`,
  });

  const handleGetMembership = () => {
    getMembership(membershipId).then((response) => {
      if (response.status === 200) {
        setValues(response.data);
      }
    });
  };

  const getFormValues = (membership) => {
    return membership
      ? {
          branchId: membership.branchId,
          name: membership.name,
          description: membership.description,
          preparation: membership.preparation,
          equipments: membership.equipments,
          duration: membership.duration,
          price: membership.price,
          id: membership.id,
          level: membership.level || LEVELS.INTERMEDIATE,
          platform: membership.platform,
        }
      : {
          branchId: "",
          name: "",
          description: "",
          preparation: "",
          equipments: [],
          duration: null,
          price: null,
          id: "",
          level: LEVELS.INTERMEDIATE,
          platform: PLATFORM.IN_PERSON,
        };
  };

  const formValues = getFormValues(membership);

  const [values, setValues] = useState(formValues);

  const shouldFetchMembershipData = showSubmitMembershipModal && membershipId;

  useEffect(() => {
    if (shouldFetchMembershipData) {
      handleGetMembership();
      setValues(formValues);
    }
  }, [shouldFetchMembershipData]);

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleSelectBranch = (id) => {
    setValues({ ...values, branchId: id });
  };

  const handleSelectLevel = (level) => {
    setValues({ ...values, level });
  };

  const handleSelectPlatform = (platform) => {
    setValues({ ...values, platform: platform });
  };

  const handleChangeEquipments = (inputValue) => {
    const equipments = inputValue.split(",");

    setValues({ ...values, equipments });
  };

  const notifyError = (text) => {
    toast.error(`❌ ${text} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isNewPackage = !membershipId;

    if (!validateMembershipForm(values)) {
      return;
    }

    const variables = {
      name: values.name,
      preparation: values.preparation,
      description: values.description,
      duration: parseInt(values.duration),
      price: parseInt(values.price),
      equipments: values.equipments
        .map((equipment) => equipment.trim())
        .filter(Boolean),
      branchId: values.branchId,
      level: values.level,
      platform: values.platform,
    };

    if (isNewPackage) {
      createMembership(variables).then((response) => {
        if (response.error) {
          notifyError(response.message);
        } else {
          onClose();
          getMemberships();
        }
      });
    } else {
      updateMembership(membershipId, variables).then((response) => {
        if (response.status === 200) {
          onClose();
          getMemberships();
        } else {
          notifyError(response.message);
          onClose();
        }
      });
    }
  };

  const buttonLabel = getLanguageObject().pages.common.form.submit_button;

  return {
    handleSubmit,
    handleChangeInput,
    handleSelectBranch,
    handleSelectLevel,
    values,
    setValues,
    handleChangeEquipments,
    handleSelectPlatform,
    buttonLabel,
  };
};

export default useMembershipForm;
